/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, navigateTo } from 'gatsby';
import styles from './header-menu.module.scss';
import VerticalLines from '../vertical-lines/ vertical-lines';
import Container from '../../layouts/container/container';
import { returnNum } from '../../helpers/helpers';

const HeaderMenu = ({
  footerData,
  animateOutMenu,
  animateOutMenuText,
  location,
  changeHandler,
  toggleMenu,
  isMobile,
  menuData,
}) => {
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [hoverItem, setHoverItem] = useState(null);
  const [topPosition, setTopPosition] = useState(false);
  const [menuVersion, setMenuVersion] = useState('menu');

  useEffect(() => {
    setMenuLoaded(true);
  }, []);
  useEffect(() => {
    if (location.pathname.indexOf('/ar') === 0) {
      setMenuVersion('menu-ar');
    }

    return () => {
      setMenuVersion('');
    };
  }, [menuData]);

  const menu = menuData && menuData.nodes.filter((item) => item.name === menuVersion)[0].items;
  const setHoverNumber = (i, e) => {
    const { top } = e.target.getBoundingClientRect();
    setTopPosition(top);
    setHoverItem(i);
  };
  const resetHoverNumber = () => {
    setHoverItem(null);
  };
  const navigateToPage = (path, i, e) => {
    if (!isMobile) {
      navigateTo(path);
    } else {
      if (i === hoverItem) {
        resetHoverNumber();
        navigateTo(path);
      } else {
        setHoverNumber(i, e);
      }
    }
  };
  const setLinks = (data) => {
    const arr = [];
    data.forEach((item) => {
      arr.push({
        name: item.title,
        path: item.url,
      });
    });
    return arr;
  };
  const ABOUT_NAMES = {
    title: menu[0].title,
    url: menu[0].url,
    links: setLinks(menu[0].child_items),
  };
  const INVESTOR_NAMES = {
    title: menu[1].title,
    url: menu[1].url,
    links: setLinks(menu[1].child_items),
  };
  const WHAT_WE_DO_NAMES = {
    title: menu[2].title,
    url: menu[2].url,
    links: setLinks(menu[2].child_items),
  };
  const CITIZEN = {
    title: menu[3].title,
    url: menu[3].url,
    links: setLinks(menu[3].child_items),
  };
  const LINKS_NAMES = [
    {
      name: menu[0].title,
      path: `${menu[0].url}`,
    },
    {
      name: menu[1].title,
      path: `${menu[1].url}`,
    },
    {
      name: menu[2].title,
      path: `${menu[2].url}`,
    },
    {
      name: menu[3].title,
      path: `${menu[3].url}`,
    },
    {
      name: menu[4].title,
      path: `${menu[4].url}`,
    },
    {
      name: menu[5].title,
      path: `${menu[5].url}`,
    },
    {
      name: menu[6].title,
      path: `${menu[6].url}`,
    },
  ];
  const renderList = (array) => {
    const isSamePage = location.pathname.includes(array.url);
    return (
      array.links.map((link) => (
        <div className={`${styles.animatedText} ${styles.about}`}>
          {isSamePage ? (
            <div
              className={styles.link}
              onClick={() => {
                changeHandler({
                  index: returnNum(link.path) - 1,
                  name: link.name,
                });
                toggleMenu();
              }}
            >
              {link.name}
            </div>
          ) : (
            <Link
              className={styles.link}
              to={link.path}
            >
              {link.name}
            </Link>
          )}

        </div>
      ))
    );
  };
  const memoLines = useMemo(() => (
    <VerticalLines isMenu animateOutMenu={animateOutMenu} />
  ), [animateOutMenu]);
  return (
    <nav className={`${styles.headerMenu} ${menuLoaded ? styles.animateIn : ''} ${animateOutMenuText ? styles.animateOut : ''}`}>
      {memoLines}
      <Container>
        <div onMouseLeave={resetHoverNumber} className={styles.colBox}>
          <div className={styles.col}>
            <div className={styles.animatedTextContainer}>
              {LINKS_NAMES.map((link, i) => (
                <div
                  onMouseEnter={(e) => !isMobile && setHoverNumber(i, e)}
                  className={styles.animatedText}
                >
                  <div
                    className={`${styles.link} ${hoverItem === i ? styles.linkActive : ''}`}
                    to={link.path}
                    onClick={(e) => {
                      const isSamePage = location.pathname.includes(link.path);
                      if (isSamePage) {
                        toggleMenu();
                      } else {
                        navigateToPage(link.path, i, e);
                      }
                    }}
                  >
                    {link.name}
                  </div>
                  {!isMobile && (
                  <div
                    style={{ transform: ((hoverItem === i && i < 4) ? 'scaleX(1)' : 'scaleX(0)') }}
                    className={styles.line}
                  />
                  )}
                  {(isMobile && i === 0 && hoverItem === 0)
                    && <div className={`${styles.innerCol} ${styles.animatedTextContainer}`}>{renderList(ABOUT_NAMES)}</div>}
                  {(isMobile && i === 1 && hoverItem === 1)
                    && <div className={`${styles.innerCol} ${styles.animatedTextContainer}`}>{renderList(INVESTOR_NAMES)}</div>}
                  {(isMobile && i === 2 && hoverItem === 2)
                    && <div className={`${styles.innerCol} ${styles.animatedTextContainer}`}>{renderList(WHAT_WE_DO_NAMES)}</div>}
                  {(isMobile && i === 3 && hoverItem === 3)
                    && <div className={`${styles.innerCol} ${styles.animatedTextContainer}`}>{renderList(CITIZEN)}</div>}
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles.col}`}>
            {!isMobile && (
            <div style={{ top: topPosition }} className={styles.animatedTextContainer}>
              {hoverItem === 0 && renderList(ABOUT_NAMES)}
              {hoverItem === 1 && renderList(INVESTOR_NAMES)}
              {hoverItem === 2 && renderList(WHAT_WE_DO_NAMES)}
              {hoverItem === 3 && renderList(CITIZEN)}
            </div>
            )}

          </div>
          <div className={styles.col}>
            <div className={styles.animatedTextContainer}>
              <div className={styles.animatedText}>
                <p className={`${styles.label} ${styles.headerMenuEmailLabel}`}>{footerData.footerEmailLabel}</p>
              </div>
              <div className={styles.animatedText}>
                <a className={`${styles.link} ${styles.headerMenuEmail}`} href={`mailto:${footerData.footerEmail}`}>{footerData.footerEmail}</a>
              </div>
            </div>
            <div className={styles.animatedTextContainer}>
              <div className={styles.animatedText}>
                <p className={`${styles.label} ${styles.headerMenuPhoneLabel}`}>{footerData.footerPhoneLabel}</p>
              </div>
              <div className={styles.animatedText}>
                <a className={`${styles.link} ${styles.headerMenuPhone}`} href={`tel:${footerData.footerPhone}`}>{footerData.footerPhone}</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};
HeaderMenu.propTypes = {
  footerData: PropTypes.objectOf(PropTypes.string).isRequired,
  animateOutMenu: PropTypes.bool.isRequired,
  animateOutMenuText: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  changeHandler: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuData: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default HeaderMenu;
