import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './footer.module.scss';
import VerticalLines from '../vertical-lines/ vertical-lines';
import Container from '../../layouts/container/container';

import FooterLogo from '../../assets/svg/footer_logo.svg';


const Footer = ({ footerData, links, location }) => {
  const [isAr, setAr] = useState(false);
  useEffect(() => {
    if (location.pathname.indexOf('/ar') === 0) {
      setAr(true);
    }
  });
  return (
    <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
      <footer className={`${styles.footer} ${styles.sections}`}>
        <VerticalLines />
        <Container>
          <div className={styles.footer_col}>
            <Link
              to={isAr ? '/ar/' : '/'}
              state={{ goToMain: true }}
            >
              <FooterLogo />
            </Link>
          </div>
          <div className={styles.footer_col}>
            <div className={styles.animatedTextContainer}>
              {links.map((link) => (
                <div key={uuidv4()} className={styles.animatedText}>
                  <Link
                    to={!isAr ? link.path : `/ar${link.path}`}
                  >
                    {link.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.footer_col}>
            <div className={styles.animatedTextContainer}>
              <div className={styles.animatedText}>
                <p className={styles.footer_label}>{footerData.footerEmailLabel}</p>
              </div>
              <div className={styles.animatedText}>
                <a
                  className={styles.footer_link}
                  href={`mailto:${footerData.footerEmail}`}
                >
                  {footerData.footerEmail}
                </a>
              </div>
              <div className={styles.animatedText}>
                <p className={styles.footer_copyright}>{footerData.copyright}</p>
              </div>
            </div>
          </div>
          <div className={styles.footer_col}>
            <div className={styles.animatedTextContainer}>
              <div className={styles.animatedText}>
                <p className={styles.footer_label}>{footerData.footerPhoneLabel}</p>
              </div>
              <div className={`${styles.animatedText} ${styles.footer_phone}`}>
                <a
                  className={styles.footer_link}
                  href={`tel:${footerData.footerPhone}`}
                >
                  {footerData.footerPhone}
                </a>
              </div>
              <div className={styles.animatedText}>
                <p className={styles.footer_copyright}>{footerData.copyright}</p>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </ScrollAnimation>
  );
};
Footer.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  footerData: PropTypes.objectOf(PropTypes.string).isRequired,
  links: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default Footer;
