/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';

import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import 'intl';
import styles from '../static/styles/main/block.module.scss';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import HeaderMenu from '../components/header-menu/header-menu';
import withMenuData from '../HOC/withMenuData';


const Layout = ({
  children,
  currentSection,
  sectionsCount,
  location,
  i18nMessages,
  footerData,
  isMobile,
  showElements,
  changeHandler,
  darkBurger,
  showFooter,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [animateOutMenu, setAnimateOutMenu] = useState(false);
  const [animateOutMenuText, setAnimateOutMenuText] = useState(false);
  useEffect(() => {
    document.querySelector('body').classList.add(styles.block);
  }, []);
  const toggleMenu = () => {
    if (animateOutMenuText) return false;
    if (showMenu) {
      setAnimateOutMenuText(true);
      setTimeout(() => {
        setAnimateOutMenu(true);
      }, 1000);
      setTimeout(() => {
        setShowMenu(!showMenu);
        setAnimateOutMenu(false);
        setAnimateOutMenuText(false);
      }, 1800);
    } else {
      setShowMenu(!showMenu);
    }
    return true;
  };
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
          allWordpressMenusMenusItems {
            nodes {
              name
              items {
                title
                url
                child_items {
                  title
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);

        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
          .map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
        const WrappedFooter = withMenuData(Footer);
        return (
          <IntlProvider
            locale={langKey}
            messages={i18nMessages}
          >
            <>
              <Header
                currentSection={currentSection}
                langs={langsMenu}
                isMobile={isMobile}
                toggleMenu={toggleMenu}
                showMenu={showMenu}
                showElements={showElements}
                darkBurger={darkBurger}
                location={location}
              />
              {showMenu && (
                <HeaderMenu
                  animateOutMenu={animateOutMenu}
                  animateOutMenuText={animateOutMenuText}
                  footerData={footerData}
                  location={location}
                  toggleMenu={toggleMenu}
                  changeHandler={changeHandler}
                  isMobile={isMobile}
                  menuData={data.allWordpressMenusMenusItems}
                />
              )}
              <main>{children}</main>
              {(showElements && showFooter
                && (currentSection === sectionsCount || isMobile))
                && (
                  <WrappedFooter
                    changeHandler={changeHandler}
                    location={location}
                    footerData={footerData}
                  />
                )}
            </>
          </IntlProvider>
        );
      }}
    />
  );
};
Layout.defaultProps = {
  showElements: true,
  darkBurger: false,
  showFooter: true,
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currentSection: PropTypes.number.isRequired,
  sectionsCount: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  i18nMessages: PropTypes.objectOf(PropTypes.any).isRequired,
  footerData: PropTypes.objectOf(PropTypes.any).isRequired,
  isMobile: PropTypes.bool.isRequired,
  showElements: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
  darkBurger: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export default Layout;
