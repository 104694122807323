// Core
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Instruments
import styles from './vertical-lines.module.scss';

const VerticalLines = ({ startAnimation, isMenu, animateOutMenu }) => {
  const LINES_COUNT = Array(6).fill(null);
  const ANIMATION_TIME = 0.3;
  const renderMemoItems = useMemo(() => (
    LINES_COUNT.map((item, index) => (
      <div
        className={styles.verticalLines_line}
        style={{ transitionDelay: `${(ANIMATION_TIME * (index + 1))}s` }}
        key={uuidv4()}
      />
    ))
  ), []);

  return (
    <div data-lines="lines" className={`${styles.verticalLines} ${startAnimation ? styles.startAnimation : ''} ${isMenu ? styles.menuStyles : ''} ${animateOutMenu ? styles.animateOutMenu : ''}`}>
      {renderMemoItems}
    </div>
  );
};
VerticalLines.propTypes = {
  startAnimation: PropTypes.bool,
  isMenu: PropTypes.bool,
  animateOutMenu: PropTypes.bool,
};
VerticalLines.defaultProps = {
  startAnimation: true,
  isMenu: false,
  animateOutMenu: false,
};

export default VerticalLines;
