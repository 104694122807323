module.exports = {
  selectLanguage: 'Select your language',
  // HOME PAGE
  'homePage.section_1.heading1': 'Inspired',
  'homePage.section_1.heading1.second_1': 'History',
  'homePage.section_1.heading1.second_2': 'People',
  'homePage.section_1.heading1.second_3': 'Strategy',
  'homePage.section_1.heading1.second_4': 'Investments',
  'homePage.section_1.link': 'Investment Portfolio',

  // Home page section 2

  'homePage.section_2.heading1.l1': 'At Esterad, we promise to turn',
  'homePage.section_2.heading1.l2': 'undervalued assets into',
  'homePage.section_2.heading1.l3': 'profit-making investments',
  'homePage.section_2.link': 'About Esterad',

  // Home page section 3
  'homePage.section_3.heading1': 'A New Direction',
  'homePage.section_3.heading2.l1': 'Our Chairman, Hisham Alrayes,',
  'homePage.section_3.heading2.l2': 'outlines his vision for the future',
  'homePage.section_3.heading2.l3': 'of Esterad',
  'homePage.section_3.text.l1': 'It gives me great pride to introduce a new ',
  'homePage.section_3.text.l2': 'Esterad to our investors, shareholders and ',
  'homePage.section_3.text.l3': 'Bahrain. We have taken bold steps to reimagine',
  'homePage.section_3.text.l4': 'and elevate our business. Our strategy has ',
  'homePage.section_3.text.l5': 'evolved and is now rooted in deeper insights',
  'homePage.section_3.text.l6': 'that, when combined with our rich experience,',
  'homePage.section_3.text.l7': ' will certainly help us perform even better ',
  'homePage.section_3.text.l8': 'and build an even brighter future.',

  'homePage.section_3.text.l9': 'We are driven by the constant desire to seek',
  'homePage.section_3.text.l10': ' lucrative investment opportunities, to take ',
  'homePage.section_3.text.l11': 'calculated risks to realise remarkable ',
  'homePage.section_3.text.l12': 'results from our investment strategy. We ',
  'homePage.section_3.text.l13': 'believe our new, widely experienced leadership ',
  'homePage.section_3.text.l14': 'team are perfectly positioned to define a new ',
  'homePage.section_3.text.l15': 'financial ecosystem and unlock doors to new,',
  'homePage.section_3.text.l16': 'exciting possibilities.',

  'homePage.section3.link': 'Meet the Team',

  // Home page section 4
  'homePage.section_4.heading1': 'Esterad has a diverse portfolio ',
  'homePage.section_4.heading2.l1': 'Our ambition is to positively contribute to the growth of the region, ',
  'homePage.section_4.heading2.l2': 'with a special focus on Bahrain`s development',
  'homePage.section_4.count1.l1': '193%',
  'homePage.section_4.count1.l2': 'Annual Return',
  'homePage.section_4.count2.l1': '440%',
  'homePage.section_4.count2.l2': 'Profits',
  // Home page section 5

  // Home page section 6

  'homePage.section_6.heading1.l1': 'Esterad has a diverse portfolio ',
  'homePage.section_6.heading1.l2': 'of three asset classes',
  'homePage.section_6.heading2.l1': 'Whilst highly diverse in nature, our investments share',
  'homePage.section_6.heading2.l2': 'certain characteristics. All placements are principled',
  'homePage.section_6.heading2.l3': 'and have undergone prudent risk control.',
  'homePage.section_6.heading2.l4': 'Our portfolio contains high-yielding investments',
  'homePage.section_6.heading2.l5': ' that drive income and dividend payments.',
  'homePage.section_6.count1.l1': '1,500',
  'homePage.section_6.count1.l2': 'Million USD',
  'homePage.section_6.count1.l3': 'Total Private Equity Assets',

  'homePage.section_6.count2.l1': '800',
  'homePage.section_6.count2.l2': 'Million USD',
  'homePage.section_6.count2.l3': 'Aggregate Estimated Real Estate',
  'homePage.section_6.count2.l4': 'Development Value',

  'homePage.section_6.count3.l1': '60/40',
  'homePage.section_6.count3.l2': 'Ratio of Liquid to Non',
  'homePage.section_6.count3.l3': 'Liquid Assets',
  'homePage.section_6.count3.l4': 'comprising the',
  'homePage.section_6.count3.l5': 'Total Holdings',

  // Home page section 7

  'homePage.section_7.heading1': 'Subscribe',
  'homePage.section_7.heading2.l1': 'To receive investment and marketing updates, our latest news and ',
  'homePage.section_7.heading2.l2': 'important information about Esterad, simply provide your details below.',
  'homePage.section_7.placeholder': 'Type in your email address ',
  'homePage.section_7.button': 'Submit',

  // ABOUT US PAGE

  'aboutUs.section_1.heading1.l1': 'Esterad’s ambition is rooted ',
  'aboutUs.section_1.heading1.l2': 'in transformative investments in',
  'aboutUs.section_1.heading1.l3': ' Bahrain and beyond.',
  // 'aboutUs.section_1.heading1.l4': 'Our successful legacy is founded on a ',
  // 'aboutUs.section_1.heading1.l5': 'well-crafted  combination of ',
  // 'aboutUs.section_1.heading1.l6': 'local principles and international expertise.',

  // About page section 1
  'aboutUs.section_2.heading1.l1': 'Our mission is to push the limits of possibility',
  'aboutUs.section_2.text1.l1': 'With a keen eye for opportunity and a sharp ',
  'aboutUs.section_2.text1.l2': 'business sense, Esterad sets the market in ',
  'aboutUs.section_2.text1.l3': 'motion, creating a financial ecosystem and ',
  'aboutUs.section_2.text1.l4': 'unlocking the door to many new possibilities. ',
  'aboutUs.section_2.text1.l5': 'Our aggressive and transformational approach ',
  'aboutUs.section_2.text1.l6': 'contributes to Bahrain’s growth, transforming ',
  'aboutUs.section_2.text1.l7': 'undervalued investments and accelerating',
  'aboutUs.section_2.text1.l8': 'the economy.',
  'aboutUs.section_2.heading2.l1': 'Our vision is to seize opportunity and optimise performance',
  'aboutUs.section_2.text2.l1': 'We are always in control, setting the pace.',
  'aboutUs.section_2.text2.l2': ' We make bold moves that make a real ',
  'aboutUs.section_2.text2.l3': 'difference – not just for our shareholders, ',
  'aboutUs.section_2.text2.l4': 'but for the communities in which we operate. ',
  'aboutUs.section_2.text2.l5': 'We take opportunities no one else takes and ',
  'aboutUs.section_2.text2.l6': 'deliver value, elevating perception and',
  'aboutUs.section_2.text2.l7': 'enhancing results.',

  // About page section 2
  // slide 1
  'aboutUs.section_3.slide1.heading1.l1': 'Hisham Alrayes',
  'aboutUs.section_3.slide1.heading2.l1': 'Chairman',

  'aboutUs.section_3.slide1.heading3.l1': 'Hisham Alrayes is a regionally renowned investment ',
  'aboutUs.section_3.slide1.heading3.l2': 'banker with more than 20 years of experience in managing ',
  'aboutUs.section_3.slide1.heading3.l3': 'and undertaking financial services, Private Equity and ',
  'aboutUs.section_3.slide1.heading3.l4': 'Real Estate investments with a value exceeding US$10 billion.',

  'aboutUs.section_3.slide1.heading3.l5': 'Hisham holds a Master’s degree with honors in Business  ',
  'aboutUs.section_3.slide1.heading3.l6': 'Administration from the University of DePaul, in Chicago, ',
  'aboutUs.section_3.slide1.heading3.l7': 'and a Bachelor’s degree in Engineering with honors from',
  'aboutUs.section_3.slide1.heading3.l8': 'the University of Bahrain. ',

  'aboutUs.section_3.slide1.heading4.l1': 'He currently holds an executive role and board membership',
  'aboutUs.section_3.slide1.heading4.l2': 'in the following companies: ',

  'aboutUs.section_3.slide1.heading5.l1': 'Board Member, GFH Financial Group',
  'aboutUs.section_3.slide1.heading5.l2': 'Chairman, Balexco',
  'aboutUs.section_3.slide1.heading5.l3': 'Board Member, Khaleeji Commercial Bank (KHCB) ',
  'aboutUs.section_3.slide1.heading5.l4': 'Chairman, Falcon Cement Company',
  'aboutUs.section_3.slide1.heading5.l5': 'Chairman, GBCORP ',
  // slide 2
  'aboutUs.section_3.slide2.heading1.l1': 'Abdulrahman Jamsheer',
  'aboutUs.section_3.slide2.heading2.l1': 'Deputy Chairman',

  'aboutUs.section_3.slide2.heading3.l1': 'Abdulrahman has been involved with Esterad since its ',
  'aboutUs.section_3.slide2.heading3.l2': 'inception and has played a vital role in the progress ',
  'aboutUs.section_3.slide2.heading3.l3': 'and development of the company. He is currently a member ',
  'aboutUs.section_3.slide2.heading3.l4': 'of the Board Executive Committee and the Nomination and ',
  'aboutUs.section_3.slide2.heading3.l5': 'Remuneration Committee. Abdulrahman is a well- known ',
  'aboutUs.section_3.slide2.heading3.l6': 'public figure in Bahrain, appointed by His Majesty ',
  'aboutUs.section_3.slide2.heading3.l7': 'the King as a member of the Shura Council since',
  'aboutUs.section_3.slide2.heading3.l8': 'it was established. ',

  'aboutUs.section_3.slide2.heading3.l9': 'Abdulrahman holds a BSC in Agriculture Science from ',
  'aboutUs.section_3.slide2.heading3.l10': 'American University of Beirut and the Engineer ',
  'aboutUs.section_3.slide2.heading3.l11': 'AGRICOLE diploma. ',

  'aboutUs.section_3.slide2.heading4.l1': 'He also holds Board positions and memberships',
  'aboutUs.section_3.slide2.heading4.l2': 'in the following companies: ',

  'aboutUs.section_3.slide2.heading5.l1': 'Chairman of Fortuna Co. W.L.L. ',
  'aboutUs.section_3.slide2.heading5.l2': 'Chairman of Delmon Poultry, Bahrain. ',
  'aboutUs.section_3.slide2.heading5.l3': 'Vice Chairman of Lona Real Estate BSC. ',
  'aboutUs.section_3.slide2.heading5.l4': 'Board Member of Cold Storage Company (BANZ), Bahrain.',
  'aboutUs.section_3.slide2.heading5.l5': 'Board Member of Bahrain Duty Free Shops Complex, Bahrain.',
  // slide 3
  'aboutUs.section_3.slide3.heading1.l1': 'Husain Sayed Ali Al Hussaini',
  'aboutUs.section_3.slide3.heading2.l1': '',

  'aboutUs.section_3.slide3.heading3.l1': 'Husain is a member of the Board of Directors, the Board ',
  'aboutUs.section_3.slide3.heading3.l2': 'Executive Committee and the Nomination and Remuneration ',
  'aboutUs.section_3.slide3.heading3.l3': 'Committee. He is currently Chief Executive – Treasury, ',
  'aboutUs.section_3.slide3.heading3.l4': 'Capital Markets & Wealth Management at the National Bank ',
  'aboutUs.section_3.slide3.heading3.l5': 'of Bahrain (NBB), which he assumed in 2017.',

  'aboutUs.section_3.slide3.heading3.l6': 'Husain completed an MBA in Marketing & Management at ',
  'aboutUs.section_3.slide3.heading3.l7': 'DePaul University, in the USA; a PMD programme for ',
  'aboutUs.section_3.slide3.heading3.l8': 'Management Development at Harvard Business School ',
  'aboutUs.section_3.slide3.heading3.l9': 'in Boston; as well as a B.A. in Economics at Concordia',
  'aboutUs.section_3.slide3.heading3.l10': 'University, in Canada. ',

  'aboutUs.section_3.slide3.heading4.l1': 'Currently, Husain holds Board Positions and memberships',
  'aboutUs.section_3.slide3.heading4.l2': 'in the following companies: ',

  'aboutUs.section_3.slide3.heading5.l1': 'Vice Chairman of the Board of Directors and Vice Chairman of',
  'aboutUs.section_3.slide3.heading5.l2': 'the Investment Committee of the Securities and Investment Company (SICO) ',
  'aboutUs.section_3.slide3.heading5.l3': 'Member of Board of Trustees in Bahrain Polytechnic. ',
  'aboutUs.section_3.slide3.heading5.l4': 'Member of the CBB Deposit Protection & Unrestricted Investors Accounts.',
  'aboutUs.section_3.slide3.heading5.l5': 'Member of Delta Mu Delta, Chicago, USA.',
  'aboutUs.section_3.slide3.heading5.l6': 'Member of Interarab Cambist Assocation.',
  'aboutUs.section_3.slide3.heading5.l7': 'Member of Harvard Business School Alumni Club, USA.',
  'aboutUs.section_3.slide3.heading5.l8': 'Member of Bahrain Financial Market Association.',

  // slide 4
  'aboutUs.section_3.slide4.heading1.l1': 'Razi Al Murbati',
  'aboutUs.section_3.slide4.heading2.l1': '',

  'aboutUs.section_3.slide4.heading3.l1': 'Razi brings to his role more than 16 years of experience ',
  'aboutUs.section_3.slide4.heading3.l2': 'in the banking and finance sector including a significant ',
  'aboutUs.section_3.slide4.heading3.l3': 'track record in Islamic Finance and Advisory. ',

  'aboutUs.section_3.slide4.heading3.l4': 'Previously, Razi served as Head of Investment Development ',
  'aboutUs.section_3.slide4.heading3.l5': '& Distribution for the GCC region at The First Investor ',
  'aboutUs.section_3.slide4.heading3.l6': 'in Qatar, a subsidiary of Barwa Bank. Prior to that, ',
  'aboutUs.section_3.slide4.heading3.l7': 'he was Regional Director of the Investment Advisory ',
  'aboutUs.section_3.slide4.heading3.l8': 'Group of Abu Dhabi Investment House. ',

  'aboutUs.section_3.slide4.heading3.l9': 'Razi is a member of the Board of Directors of Esterad, ',
  'aboutUs.section_3.slide4.heading3.l10': 'as well as the Chairman of the Audit & Risk Committee. ',

  'aboutUs.section_3.slide4.heading4.l1': 'He currently holds an executive role and board memberships',
  'aboutUs.section_3.slide4.heading4.l2': 'in the following companies: ',

  'aboutUs.section_3.slide4.heading5.l1': 'Chief Executive Officer, GFH Capital SA',
  'aboutUs.section_3.slide4.heading5.l2': 'Board Member of Global Banking Corporation',
  'aboutUs.section_3.slide4.heading5.l3': 'Board Member of Falcon Cement Company ',

  // slide 5
  'aboutUs.section_3.slide5.heading1.l1': 'Abdulla Jamsheer',
  'aboutUs.section_3.slide5.heading2.l1': '',

  'aboutUs.section_3.slide5.heading3.l1': 'Abdulla is a prominent business person and asset manager ',
  'aboutUs.section_3.slide5.heading3.l2': 'in Bahrain and is a member of the Board of Directors, ',
  'aboutUs.section_3.slide5.heading3.l3': 'the Audit and Risk Committee of Esterad. ',
  'aboutUs.section_3.slide5.heading3.l4': 'He holds a Bachelor\'s degree majoring in Philosophy ',
  'aboutUs.section_3.slide5.heading3.l5': 'and Psychology from Alexandria University in Egypt.',
  'aboutUs.section_3.slide5.heading3.l6': ' He has over 30 years of experience in the regional ',
  'aboutUs.section_3.slide5.heading3.l7': 'financial markets comprising different asset classes ',
  'aboutUs.section_3.slide5.heading3.l8': 'to include equities, private equity, and real estate. ',

  'aboutUs.section_3.slide5.heading4.l1': 'Currently, Abdulla holds the following positions: ',

  'aboutUs.section_3.slide5.heading5.l1': 'Director, United Paper Industries, Bahrain. ',
  'aboutUs.section_3.slide5.heading5.l2': 'Chairman of Jamsheer Investments, Bahrain. ',
  'aboutUs.section_3.slide5.heading5.l3': 'Director, Sanad Investments, Bahrain.',
  'aboutUs.section_3.slide5.heading5.l4': 'Chairman of Egyptian European Investment Company, Egypt. ',

  // slide 6
  'aboutUs.section_3.slide6.heading1.l1': 'Husain Amer',
  'aboutUs.section_3.slide6.heading2.l1': '',

  'aboutUs.section_3.slide6.heading3.l1': 'Husain is a member of the Board of Directors of ',
  'aboutUs.section_3.slide6.heading3.l2': 'Esterad and a member of the Audit and Risk Committee. ',
  'aboutUs.section_3.slide6.heading3.l3': 'He has over 30 years of experience in corporate and ',
  'aboutUs.section_3.slide6.heading3.l4': 'private banking, ten of which he spent working with ',
  'aboutUs.section_3.slide6.heading3.l5': 'Chase Manhattan Bank where he held an esteemed ',
  'aboutUs.section_3.slide6.heading3.l6': 'position as Head of Private Banking. ',

  'aboutUs.section_3.slide6.heading3.l7': 'In 1994, Husain founded Galaxy Investment Company, ',
  'aboutUs.section_3.slide6.heading3.l8': 'specialised in Asset Management and in 1999, ',
  'aboutUs.section_3.slide6.heading3.l9': 'he founded and is the current Chairman of Gulf ',
  'aboutUs.section_3.slide6.heading3.l10': 'Dental Specialty Hospital. He holds a Diploma ',
  'aboutUs.section_3.slide6.heading3.l11': 'in Economics & Accounting from Bahrain Bankers',
  'aboutUs.section_3.slide6.heading3.l12': 'training centre. ',

  'aboutUs.section_3.slide6.heading3.l13': 'In the past, Husain was Board of Director and ',
  'aboutUs.section_3.slide6.heading3.l14': 'Chairman of the Audit Committee of Bahraini Saudi Bank, ',
  'aboutUs.section_3.slide6.heading3.l15': 'a member of the Board of Directors of Bahrain ',
  'aboutUs.section_3.slide6.heading3.l16': 'Aluminium Extrusion Company (BALEXCO), and a member',
  'aboutUs.section_3.slide6.heading3.l17': ' of the Board of Directors and Executive Committee ',
  'aboutUs.section_3.slide6.heading3.l18': 'member of Bahrain Livestock Company. ',

  // slide 7
  'aboutUs.section_3.slide7.heading1.l1': 'Mazen I. Abdulmalik',
  'aboutUs.section_3.slide7.heading2.l1': '',

  'aboutUs.section_3.slide7.heading3.l1': 'Mazen brings to Esterad 26 years of experience in ',
  'aboutUs.section_3.slide7.heading3.l2': 'banking, private equity and investments, gained ',
  'aboutUs.section_3.slide7.heading3.l3': 'in major financial centres such as London, Hong Kong, ',
  'aboutUs.section_3.slide7.heading3.l4': 'New York and, more recently, Bahrain. Mazen is ',
  'aboutUs.section_3.slide7.heading3.l5': 'a member of the Board of Directors, the Board ',
  'aboutUs.section_3.slide7.heading3.l6': 'Executive Committee and the Nomination ',
  'aboutUs.section_3.slide7.heading3.l7': 'and Remuneration Committee. ',

  'aboutUs.section_3.slide7.heading4.l1': 'Mazen currently runs a family office and a proprietary ',
  'aboutUs.section_3.slide7.heading4.l2': 'investment portfolio. In addition, his other directorships include:  ',

  'aboutUs.section_3.slide7.heading5.l1': 'Al Jazeera Tourism Company B.S.C(c) Bahrain. ',
  'aboutUs.section_3.slide7.heading5.l2': 'Gulf Investment Corporation G.S.C. Kuwait. ',

  // About page section 5
  // slide 1
  'aboutUs.section_5.slide1.heading1.l1': 'Chandan Gupta',
  'aboutUs.section_5.slide1.heading2.l1': 'General Manager',

  'aboutUs.section_5.slide1.heading3.l1': 'Chandan has over 20 years of experience in Banking ',
  'aboutUs.section_5.slide1.heading3.l2': 'and Finance undertaking and managing investments in ',
  'aboutUs.section_5.slide1.heading3.l3': 'excess of US$ 5 billion; and was previously the Group ',
  'aboutUs.section_5.slide1.heading3.l4': 'Chief Financial Officer at GFH Financial Group BSC.',
  'aboutUs.section_5.slide1.heading3.l5': ' Before joining GFH in 2005, Chandan worked with HSBC ',
  'aboutUs.section_5.slide1.heading3.l6': 'and Price Waterhouse Coopers in Mumbai, India. ',

  'aboutUs.section_5.slide1.heading3.l7': 'He is a Certified Public Accountant (CPA) from ',
  'aboutUs.section_5.slide1.heading3.l8': 'the American Institute of Certified Public Accountants, ',
  'aboutUs.section_5.slide1.heading3.l9': 'a Certified Financial Analyst (CFA-ICFAI) from the ',
  'aboutUs.section_5.slide1.heading3.l10': 'Institute of Certified Financial Analysts of India, ',
  'aboutUs.section_5.slide1.heading3.l11': 'a Chartered Accountant (CA) from the Institute of ',
  'aboutUs.section_5.slide1.heading3.l12': 'Chartered Accountants of India and holds a Bachelor’s ',
  'aboutUs.section_5.slide1.heading3.l13': 'degree of Commerce (B.Com) from University of Mumbai. ',

  'aboutUs.section_5.slide1.heading4.l1': 'Chandan currently holds positions in the following companies: ',

  'aboutUs.section_5.slide1.heading5.l1': 'Director, Lefebvre Gulf BSC, Bahrain ',
  'aboutUs.section_5.slide1.heading5.l2': 'Director, Oman Aluminium Cast, Oman',
  'aboutUs.section_5.slide1.heading5.l3': 'Director, Esterad Amwaj Co. WLL, Bahrain ',

  // slide 2
  'aboutUs.section_5.slide2.heading1.l1': 'Hanan Abdulghani',
  'aboutUs.section_5.slide2.heading2.l1': 'Head of Direct Investment',

  'aboutUs.section_5.slide2.heading3.l1': 'Hanan is the Head of Direct Investments at Esterad',
  'aboutUs.section_5.slide2.heading3.l2': 'with an experience of over 15 years in the investment',
  'aboutUs.section_5.slide2.heading3.l3': 'field. Her areas of expertise include Private Equity, ',
  'aboutUs.section_5.slide2.heading3.l4': 'Real Estate, Corporate Finance, Asset Management ',
  'aboutUs.section_5.slide2.heading3.l5': 'and General Portfolio Management.',

  'aboutUs.section_5.slide2.heading3.l6': 'Hanan joined Esterad in 2005. She holds a BSc. ',
  'aboutUs.section_5.slide2.heading3.l7': 'in Banking & Finance and a CFA charter awarded  ',
  'aboutUs.section_5.slide2.heading3.l8': 'by the CFA Institute. ',

  'aboutUs.section_5.slide2.heading4.l1': 'Hanan is a member or director in the following organizations: ',

  'aboutUs.section_5.slide2.heading5.l1': 'Chairwoman, Lefebvre Gulf B.S.C., Bahrain. ',
  'aboutUs.section_5.slide2.heading5.l2': 'Director, Oman Aluminium Cast, Oman. ',
  'aboutUs.section_5.slide2.heading5.l3': 'Director, First Bahrain Real Estate Development Co., Kuwait. ',
  'aboutUs.section_5.slide2.heading5.l4': 'Member, CFA Society, Bahrain. ',

  // slide 3
  'aboutUs.section_5.slide3.heading1.l1': 'Abdulrahman Al Malki',
  'aboutUs.section_5.slide3.heading2.l1': 'Head of Business Development',

  'aboutUs.section_5.slide3.heading3.l1': 'Abdulrahman is a seasoned executive who has held a ',
  'aboutUs.section_5.slide3.heading3.l2': 'number of senior roles across banking and investments ',
  'aboutUs.section_5.slide3.heading3.l3': 'over the past 16 years. Prior to Esterad, he served ',
  'aboutUs.section_5.slide3.heading3.l4': 'as an Executive Vice President at National Investment Co, ',
  'aboutUs.section_5.slide3.heading3.l5': 'one of the leading investment firms in Kuwait, ',
  'aboutUs.section_5.slide3.heading3.l6': 'where he established and managed the Wealth Management ',
  'aboutUs.section_5.slide3.heading3.l7': 'business. Previously he was part of the senior management',
  'aboutUs.section_5.slide3.heading3.l8': ' team of GFH Financial Group, leading the revitalization ',
  'aboutUs.section_5.slide3.heading3.l9': 'and the restructuring of the group. Prior to that, he held ',
  'aboutUs.section_5.slide3.heading3.l10': 'positions in various regional financial institutions ',
  'aboutUs.section_5.slide3.heading3.l11': 'such as Bank Al Khair, Real Capita, and Gulf International Bank. ',

  'aboutUs.section_5.slide3.heading3.l12': 'Abdulrahman completed his BSc in Management Information ',
  'aboutUs.section_5.slide3.heading3.l13': 'System with distinction from the University of Bahrain,',
  'aboutUs.section_5.slide3.heading3.l14': ' and is pursuing an EMBA from Hult Business School. ',
  'aboutUs.section_5.slide3.heading3.l15': 'Similarly, he completed the Leadership Grooming Program ',
  'aboutUs.section_5.slide3.heading3.l16': 'for Islamic banks in Ivey Business School, Canada and ',
  'aboutUs.section_5.slide3.heading3.l17': 'has been elected as “Best Placement Manager” several ',
  'aboutUs.section_5.slide3.heading3.l18': 'times across entities. He also holds a certificate with ',
  'aboutUs.section_5.slide3.heading3.l19': 'distinction in Investor relations from JP Morgan. ',

  // slide 4
  'aboutUs.section_5.slide4.heading1.l1': 'Abdulla Nooruddin',
  'aboutUs.section_5.slide4.heading2.l1': 'Head of Real Estate',

  'aboutUs.section_5.slide4.heading3.l1': 'With over 15 years of experience in real estate  ',
  'aboutUs.section_5.slide4.heading3.l2': 'and investments, Abdulla has served renowned ',
  'aboutUs.section_5.slide4.heading3.l3': 'private and public sector institutions throughout ',
  'aboutUs.section_5.slide4.heading3.l4': 'his career. He previously headed the Investment ',
  'aboutUs.section_5.slide4.heading3.l5': 'and Strategic Planning department at Amlak Social ',
  'aboutUs.section_5.slide4.heading3.l6': 'Insurance and prior to that held senior roles at ',
  'aboutUs.section_5.slide4.heading3.l7': 'both Mumtalakat and Edamah (entities representing ',
  'aboutUs.section_5.slide4.heading3.l8': 'the sovereign wealth fund of Bahrain). He has also ',
  'aboutUs.section_5.slide4.heading3.l9': 'held earlier positions at Venture Capital Bank, GIB,',
  'aboutUs.section_5.slide4.heading3.l10': ' as well as KPMG.',

  'aboutUs.section_5.slide4.heading3.l11': 'Abdulla is a holder of the CFA charter, awarded by ',
  'aboutUs.section_5.slide4.heading3.l12': 'the CFA institute, in the USA, and has a Master’s degree ',
  'aboutUs.section_5.slide4.heading3.l13': 'in Business Information Technologies from Northumbria ',
  'aboutUs.section_5.slide4.heading3.l14': 'University in Newcastle, UK. He also holds a Bachelor’s ',
  'aboutUs.section_5.slide4.heading3.l15': 'degree in Economics from Clark University',
  'aboutUs.section_5.slide4.heading3.l16': 'in Massachusetts, USA.',

  'aboutUs.section_5.slide4.heading4.l1': 'He currently has positions in the following companies: ',

  'aboutUs.section_5.slide4.heading5.l1': 'Director, Esterad Amwaj Co. WLL, Bahrain ',
  'aboutUs.section_5.slide4.heading5.l2': 'Director, Amwaj Islands Central Owners Association, Bahrain',
  'aboutUs.section_5.slide4.heading5.l3': 'Director, Mayflower Investments, Bahrain',
  'aboutUs.section_5.slide4.heading5.l4': 'Director, Leaves Holding WLL ',

  // slide 5
  'aboutUs.section_5.slide5.heading1.l1': 'Mohammed Noor',
  'aboutUs.section_5.slide5.heading2.l1': 'Head of Non-Direct Investment',

  'aboutUs.section_5.slide5.heading3.l1': 'Mohammed holds a BSc in Business Administration ',
  'aboutUs.section_5.slide5.heading3.l2': 'from the University of Bahrain. Over the past 25 years, ',
  'aboutUs.section_5.slide5.heading3.l3': 'he has acquired vast experience in portfolio management, ',
  'aboutUs.section_5.slide5.heading3.l4': 'project finance, project analysis, and marketing.',
  'aboutUs.section_5.slide5.heading3.l5': 'He is an investment professional specializing in GCC ',
  'aboutUs.section_5.slide5.heading3.l6': 'and International Equity and Fixed Income markets.',

  'aboutUs.section_5.slide5.heading3.l7': 'Mohammed’s career started at Merrill Lynch International, ',
  'aboutUs.section_5.slide5.heading3.l8': 'the Bahrain Saudi Aluminium Marketing Co. (BALCO) and ',
  'aboutUs.section_5.slide5.heading3.l9': 'Bahrain Development Bank before joining Esterad',
  'aboutUs.section_5.slide5.heading3.l10': 'in 1998 as an Investment Manager.',


  // About page section 6
  'aboutUs.section_6.slide1.number': '1973',
  'aboutUs.section_6.slide1.text': 'Esterad was established as The National Imports and Exports Company',
  // 2
  'aboutUs.section_6.slide2.number': '1974',
  'aboutUs.section_6.slide2.text': 'Esterad is publicly listed for the first time',
  // 3
  'aboutUs.section_6.slide3.number': '1976',
  'aboutUs.section_6.slide3.text': 'Creation of Saudi Cement Company, now the largest cement production company in KSA',
  // 4
  'aboutUs.section_6.slide4.number': '1995',
  'aboutUs.section_6.slide4.text': 'Investment in Balexco – First privatisation by the government of Bahrain',
  // 5
  'aboutUs.section_6.slide5.number': '1999',
  'aboutUs.section_6.slide5.text': 'Creation of Bahrain Livestock firm',
  // 6
  'aboutUs.section_6.slide6.number': '2001',
  'aboutUs.section_6.slide6.text.l1': 'New strategic direction of focusing on diversified minority investments and establishment',
  'aboutUs.section_6.slide6.text.l2': 'of the United Cement Company',
  // 7
  'aboutUs.section_6.slide7.number': '2002',
  'aboutUs.section_6.slide7.text': 'Strategic acquisition of Bahrain Duty Free',
  // 8
  'aboutUs.section_6.slide8.number': '2003',
  'aboutUs.section_6.slide8.text': 'Strategic acquisition of the District Cooling Company Tabreed',
  // 9
  'aboutUs.section_6.slide9.number': '2008',
  'aboutUs.section_6.slide9.text': 'Creation of a new treasury department within Esterad',
  // 10
  'aboutUs.section_6.slide10.number': '2009',
  'aboutUs.section_6.slide10.text': ' Capital increased and adoption of highly liquid, low-risk, high-yield investment strategy',
  // 11
  'aboutUs.section_6.slide11.number': '2018',
  'aboutUs.section_6.slide11.text.l1': 'Manarat becomes the largest shareholder in Esterad, inspiring a new investment strategy',
  'aboutUs.section_6.slide11.text.l2': 'focussed on principal investments and exit of non-core assets',
  // 12
  'aboutUs.section_6.slide12.number': '2020',
  'aboutUs.section_6.slide12.text': 'Esterad presents a new brand image – bolder and fully aligned with a new ambition for the new decade',

  // WHAT WE DO PAGE
  // 1
  'whatWeDo.section_1.heading1': 'We find, chase and make opportunities in the market and thrive by transforming ideas with potential into inspired investments.',
  // 2
  'whatWeDo.section_2.heading1': 'Public Markets',
  'whatWeDo.section_2.text1': 'For nearly half a century, Esterad has been investing capital across both Equities and Fixed Income markets in Bahrain, regionally and overseas. ',
  'whatWeDo.section_2.text2': 'Given our experience in these fields, we are able to spot niche opportunities and market gaps to invest shareholder capital for lucrative returns. ',
  'whatWeDo.section_2.text3': 'Our public markets portfolio is highly liquid and invested in high-quality, high-yielding investments driving our income and dividends. ',
  // 3
  'whatWeDo.section_3.heading1': 'Private Equity',
  'whatWeDo.section_3.text1': 'Esterad is one of the most experienced investment firms in Bahrain with nearly 50 years’ track record in successful investment in Private Equity. ',
  'whatWeDo.section_3.text2': 'Historically, Esterad has invested as a significant minority investor in diversified industries including Cement, Aluminium, Retail, Infrastructure and Technology, across the GCC. ',
  'whatWeDo.section_3.text3': 'Esterad\'s investment strategy has shifted and is now focused on \'Value Investing\' in overlooked opportunities in the GCC. Each investment is carefully assessed by Esterad’s highly experienced investment team, based on stringent risk parameters. We acquire majority shareholding in the investee, along with our strategic relationships, and works with the management to drive growth in the company, targeting high capital gains as part of our exit strategy. ',
  // 4
  'whatWeDo.section_4.heading1': 'Real Estate',
  'whatWeDo.section_4.text1': 'Esterad invests proprietary capital across a broad spectrum of Real Estate investments in lucrative locations and sought-after destinations. ',
  'whatWeDo.section_4.text2': 'Our strategy is based on identifying key target investments and opportunities which have the potential for high capital growth and/or strong yields. Esterad investments in real estate development projects are very selective. For residential developments, this is typically near sought-after beachfronts or waterfront locations. ',
  'whatWeDo.section_4.text3': 'Esterad\'s income-yielding real estate investments span across various asset classes covering sectors such as commercial, logistics, retail, labour accommodation, and others. ',

  // Investor Relations
  // 1
  'investorRelations.section_1.heading1': 'After a brief period of quiescence, Esterad is back and stronger than ever with a new, bold and assertive strategy. ',
  // 2
  'investorRelations.section_2.heading1': 'Corporate Governance ',
  'investorRelations.section_2.text1': 'We aim to establish a system that governs Esterad’s business and practices in order to create an efficient system that contributes to a strong, transparent and competitive national economy.',
  'investorRelations.section_2.text2': 'As per our organisational structure, our Board of Directors plays a pivotal role in Esterad’s corporate governance. It is ultimately responsible for compliance with our legal and regulatory obligations, our Memorandum and Articles of Association, and duties to our valued shareholders. ',
  'investorRelations.section_2.text3': 'This is achieved with collaboration from all parties including the Board’s sub-committees, particularly the Board Executive Committee, the Board Audit & Risk Committee and the Board Nomination & Remuneration Committee, in union with the external and internal auditors, as well as Esterad’s officers and employees. ',
};
