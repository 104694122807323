export const transformPostDate = (postDate) => {
  const date = new Date(postDate);
  const day = date.getDate();
  const month = date.toLocaleString('en', { month: 'long' });
  const year = date.getFullYear();
  return {
    day,
    month,
    year,
  };
};
export const replaceSpace = (str) => str.replace(/ /g, '-').replace('&', '');

export const returnNum = (str) => {
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num, 10);
};

export const setRtl = (pathname) => {
  if (pathname.indexOf('/ar') === 0) {
    document.body.setAttribute('dir', 'rtl');
  }
};

export const unsetRtl = () => {
  document.body.removeAttribute('dir');
};

// eslint-disable-next-line max-len
export const getPageData = (data, slug) => data.allWordpressPage.edges.filter((item) => item.node.slug === slug)[0].node.acf;
export const getPostData = (data) => data.allWordpressPost.edges;


export const parseArabic = (str) => Number(str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d) => d.charCodeAt(0) - 1776));

export const toAr = (str) => str.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
