/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Core
import React from 'react';
import PropTypes from 'prop-types';

// Instruments
import styles from './burger.module.scss';

const Burger = ({ toggleMenu, showMenu, darkBurger }) => (
  <div onClick={toggleMenu} className={`${styles.burger} ${showMenu ? styles.showMenu : ''} ${darkBurger ? styles.darkBurger : ''}`} />
);

Burger.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  darkBurger: PropTypes.bool.isRequired,
};
export default Burger;
