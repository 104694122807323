// Core
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

// Instruments
import Burger from '../burger/burger';
import styles from './header.module.scss';
import LogoFullAr from '../../assets/svg/logo_full_arb.svg';
import LogoFull from '../../assets/svg/logo_full.svg';
import Logo from '../../assets/svg/logo.svg';

const Header = ({
  currentSection,
  isMobile,
  toggleMenu,
  showMenu,
  showElements,
  darkBurger,
  location,
}) => {
  const [lngOpen, setLngOpen] = useState(false);
  const [arPath, setArPath] = useState('');
  const [isEnLng, setEnLng] = useState(false);
  const [showFullLogo, setShowFullLogo] = useState(false);
  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', () => {
        setShowFullLogo(window.pageYOffset > 0);
      });
    }
  }, []);
  useEffect(() => {
    setArPath(location.pathname.replace('/ar', ''));
    setEnLng(location.pathname.indexOf('/ar') === -1);
  }, []);
  const logoFullJSX = () => (
    isEnLng ? <LogoFull /> : <LogoFullAr />
  );
  const renderLogoJSX = () => {
    if (showMenu) {
      return null;
    }
    if (isMobile) {
      return <Logo />;
    }
    return currentSection === 1 ? logoFullJSX() : <Logo />;
  };
  return (
    <header id="header" data-currentpage={`currentPage_${currentSection}`} className={`${styles.header} ${(isMobile && showFullLogo) ? styles.showSmallLogo : ''}`}>
      <Link
        to={isEnLng ? '/' : 'ar/'}
      >
        {renderLogoJSX()}
      </Link>
      <div className={styles.headerControl}>
        <div className={`lng-selector ${styles.lng}`}>
          <button
            type="button"
            onClick={() => setLngOpen(!lngOpen)}
            className={styles.lngCurrent}
          >
            {isEnLng ? 'En' : 'عربي'}
          </button>
          <div className={`${styles.lngList} ${lngOpen ? `${styles.active}` : null}`}>
            <div className={styles.lngItem}>
              {!isEnLng ? (
                <Link to={`${arPath}`}>En</Link>
              ) : (
                <Link to={`/ar/${arPath}`}>عربي</Link>
              )}
            </div>
          </div>
        </div>
        {showElements
        && <Burger toggleMenu={toggleMenu} showMenu={showMenu} darkBurger={darkBurger} />}
      </div>
    </header>
  );
};
Header.propTypes = {
  currentSection: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  showElements: PropTypes.bool.isRequired,
  darkBurger: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Header;
