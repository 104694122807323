/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const withMenuData = (WrappedComponent) => {
  const HOC = (props) => {
    const {
      footerLink1, footerLink2, footerLink3, footerLink4, footerLink5, footerLink6, footerLink7,
    } = props.footerData;
    const LINKS_NAMES = [
      {
        name: footerLink1,
        path: '/about-us',
      },
      {
        name: footerLink2,
        path: '/investor-relations',
      },
      {
        name: footerLink3,
        path: '/what-we-do',
      },
      {
        name: footerLink7,
        path: '/corporate-citizenship',
      },
      {
        name: footerLink4,
        path: '/newsroom',
      },
      {
        name: footerLink5,
        path: '/careers',
      },
      {
        name: footerLink6,
        path: '/contact',
      }];
    const memoWrapped = useMemo(() => (
      <WrappedComponent
        {...props}
        links={LINKS_NAMES}
        location={props.location}
        changeHandler={props.changeHandler}
      />
    ), []);
    return (
      memoWrapped
    );
  };
  HOC.propTypes = {
    footerData: PropTypes.objectOf(PropTypes.string).isRequired,
  };
  return HOC;
};

export default withMenuData;
